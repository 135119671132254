import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userLogin } from "components/service/backoffice.service";
import { notification } from "antd";

export default function SignIn() {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    if (!emailAddress || !password) {
      notification.error({
        message: 'Email and password are required',
      });
      return;
    }

    userLogin({ emailAddress, password })
      .then((res) => {
        const token = res.data.token;
        const status = res.data.status;
        const message = res.data.message;
        const adminRole = res.data.data.adminRole[0].name; 
        const username = res.data.data.firstName + ' ' + res.data.data.lastName;
        sessionStorage.setItem('maxon-token', token);
        sessionStorage.setItem('adminRole', adminRole);
        sessionStorage.setItem('maxon-username', username);

        notification.success({
          message: 'Logged In Successfully',
        })
        if(status === 'failure' && message === 'admin must change password'){
          navigate('/change-password')
        }else {
          navigate('/admin/default')
        }
        
      })
      .catch((err) => {
        console.log("Error: ", err)
        notification.error({
          message: 'Failed to login',
        })
      })

  }

  return (
    <div className="mt-16 mb-16 flex h-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center ">
      {/* Sign in section */}
      <form onSubmit={handleLogin} className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px] shadow-2xl" style={{ padding: '2rem' }}>
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white text-center">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600 text-center">
            Enter your email and password to sign in!
          </p>
          {/* Email */}
          <label htmlFor="email" className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium">Email*</label>
          <input
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
            placeholder="mail@massitec.com"
            id="email"
            type="text"
            onChange={(e) => setEmailAddress(e.target.value)}
          />

          {/* Password */}
          <label htmlFor="password" className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium">Password*</label>
          <input
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* Checkbox */}
          <div className="mb-4 mt-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
          </div>
          <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
}
