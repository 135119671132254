import React, { useEffect, useState } from 'react'
import {
    Table,
    Button,
    Form,
    Modal,
    notification,
} from "antd";
import { FiSearch } from 'react-icons/fi';
import { getAllCorporates } from 'components/service/corporate.service';
import { disableCorporate } from 'components/service/corporate.service';
import styled from 'styled-components';
import { searchCorporate } from 'components/service/corporate.service';


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};

const HoverButton = styled(Button)`
    background-color: #008CBA;
    color: white;

    &:hover {
        background-color: #008CBA;
    }
`;


const Corporate = () => {
    const [form] = Form.useForm()
    const [isUpdateUserModalVisible, setIsUpdateUserModalVisible] = useState(false);
    const [isViewUserModalVisible, setIsViewUserModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [corporate, setCorporate] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchField, setSearchField] = useState(''); 

    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedUser(post);
                        setIsViewUserModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "name", title: "Corporate Name", dataIndex: "name" },
        { key: "emailAddress", title: "Email", dataIndex: "emailAddress" },
        { key: "location", title: "Location", dataIndex: "location" },
        {
            key: "disabled",
            title: "Disabled",
            dataIndex: "disabled",
            render: (disabled) => disabled ? "Yes" : "No"
        },
        {
            key: "action", title: "Action", align: "center",
            render: (user) => [
                <Button
                    onClick={() => {
                        setSelectedUser(user);
                        setIsViewUserModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                user && user.disabled ? (
                    <Button
                        style={{ backgroundColor: "#52c41a", color: "white" }}
                        onClick={() => {
                            confirmDisableOrEnableCorporate(user, false);
                        }}
                    >
                        Enable
                    </Button>
                ) : (
                    <HoverButton
                        onClick={() => {
                            confirmDisableOrEnableCorporate(user, true);
                        }}
                    >
                        Disable
                    </HoverButton>
                )
            ],
        },
    ]);

    const confirmDisableOrEnableCorporate = (user, shouldDisable) => {
        Modal.confirm({
            title: shouldDisable ? 'Confirm Disable' : 'Confirm Enable',
            content: `Are you sure you want to ${shouldDisable ? 'disable' : 'enable'} this user?`,
            onOk() {
                handleDisableOrEnableCorporate(user, shouldDisable);
            },
        });
    };

    const getCorporate = () => {
        getAllCorporates().then((response) => {
            setCorporate(response.data);
            console.log("Users: ", response.data)
        }).catch((error) => {
            console.log("Error: ", error)
        })
    }

    const editCorporate = (post) => {
        setSelectedUser(post);
        form?.setFieldsValue({
            disabled: post.disabled,
        });
        setIsUpdateUserModalVisible(true);
    };

    const handleDisableOrEnableCorporate = (vehicle, shouldDisable) => {
        if (vehicle) {
            disableCorporate(vehicle.id, { disabled: shouldDisable })
                .then((response) => {
                    notification.success({
                        message: "User status updated successfully",
                    });
                    getCorporate();
                }).catch((error) => {
                    notification.error({
                        message: "Failed to update User status",
                    });
                })
        } else {
            console.error('user is null');
        }
    }

    const handleSearch = async (searchValue) => {
        if (searchValue.length > 0) {
            let searchParams = {};
            if (searchField === 'emailAddress') {
                searchParams.registrationNumber = searchValue;
            } else if (searchField === 'name') {
                searchParams.name = searchValue;
            }
            else if (searchField === 'location') {
                searchParams.location = searchValue;
            }
            searchCorporate(searchParams)
                .then((response) => {
                    setSearch(response.data);
                }).catch((error) => {
                    console.log("Error: ", error)
                })
        } else {
            notification.error({
                message: "No record found for the search value",
            });
            setSearch([]);
        }
    };

    useEffect(() => {
        getCorporate()
    }, []);

    return (
        <div style={{ marginTop: '5rem' }}>
            {/* modal view  */}
            <Modal
                open={isViewUserModalVisible}
                onCancel={() => {
                    setIsViewUserModalVisible(false);
                }}
                footer={[
                    <Button
                        onClick={() => {
                            setIsViewUserModalVisible(false);
                        }}
                    >
                        Cancel
                    </Button>,
                    <Button
                        style={{ backgroundColor: "#2698FF", color: "white" }}
                        onClick={() => {
                            setIsViewUserModalVisible(false);
                            editCorporate(selectedUser);
                        }}
                    >
                        Edit{" "}
                    </Button>,
                ]}
            >
                <Form
                    {...layout}
                    name="nest-messages"
                    form={form}
                    style={{ paddingTop: "35px" }}
                >
                    <Form.Item name={"name"} label="Corporate Name">
                        <p style={{ margin: 0 }}>{selectedUser?.name}</p>
                    </Form.Item>

                    <Form.Item name={"emailAddress"} label="Email">
                        <p style={{ margin: 0 }}>{selectedUser?.emailAddress}</p>
                    </Form.Item>

                    <Form.Item name={"location"} label="Location">
                        <p style={{ margin: 0 }}>{selectedUser?.location}</p>
                    </Form.Item>
                    <Form.Item name={"disabled"} label="Disabled">
                        <p style={{ margin: 0 }}>{String(selectedUser?.disabled)}</p>
                    </Form.Item>
                </Form>
            </Modal>

            {/* main content */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1.5rem' }}>
                <div className="relative mt-[3px] flex h-[45px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:flex-grow-0 md:gap-1 xl:w-[250px] xl:gap-2">
                    <div className="flex h-full items-center rounded-full bg-white  text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                        <p className="pl-3 pr-2 text-xl">
                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                        </p>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="block h-full w-full rounded-full bg-white  text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch(e.target.value);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* <Table columns={tableCols} dataSource={corporate} /> */}
            <Table columns={tableCols} dataSource={search.length > 0 ? search : corporate} />
        </div>
    )
}

export default Corporate