import React from 'react';
import { useNavigate } from 'react-router-dom';

export function withAdminProtection(Component) {
  return function ProtectedRoute(props) {
    const adminRole = props.adminRole; // get the admin role from props
    const navigate = useNavigate();
    if (adminRole === 'SUPER_ADMIN') {
      return <Component {...props} />;
    } else {
        navigate('/forbidden');
        return null;
    }
  }
}