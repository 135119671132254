import { notification } from "antd";
import { changeAdminPassword } from "components/service/backoffice.service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


export default function ResetPassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        e.preventDefault();
        changeAdminPassword({
            oldPassword,
            newPassword,
            confirmPassword
        }).then((response) => {
            notification.success({
                message: 'Success',
            })
            navigate('/admin/default')
        }).catch((error) => {
            notification.error({
                message: 'Error',
            })
        })
    }

    return (
        <div className="mt-16 mb-16 flex h-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center ">
            <form onSubmit={handlePasswordChange} className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px] shadow-2xl" style={{ padding: '2rem' }}>
                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white text-center">
                        Reset Your Password
                    </h4>
                    <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        placeholder="old password"
                        id="email"
                        type="password"
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        placeholder="new password"
                        id="email"
                        type="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        placeholder="confirm password"
                        id="email"
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
    );
}
