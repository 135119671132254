import PieChart from "components/charts/PieChart";
import { pieChartOptions } from "variables/charts";
import Card from "components/card";
import { useEffect, useState } from "react";
import { getDashbaordData } from "components/service/dashboard.service";

const PieChartCard = () => {
  const [data, setData] = useState({ data: { userCount: 0, corporateUsersCount: 0, customerUsersCount: 0 } });

  // Assuming you have data available
  const { userCount, corporateUsersCount, customerUsersCount } = data.data;

  // Prepare the data for the pie chart
  const pieChartData = [userCount, corporateUsersCount, customerUsersCount];

  // Update the labels for the pie chart options
  pieChartOptions.labels = ['Users', 'Corporate Users', 'Customer Users'];

  const total = pieChartData.reduce((a, b) => a + b, 0);
  const usersPercentage = Math.round((pieChartData[0] / total) * 100);
  const corporateUsersPercentage = Math.round((pieChartData[1] / total) * 100);
  const customerUsersPercentage = Math.round((pieChartData[2] / total) * 100);

  const getAllDashbaordData = async () => {
    getDashbaordData().then((res) => {
      setData(res.data)
      console.log("Dashboard data is", res.data)
    }).catch((err) => {
      console.log("Error while fetching dashboard data", err)
    })
  }

  useEffect(() => {
    getAllDashbaordData()
  }, [])

  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            System Users
          </h4>
        </div>

        <div className="mb-6 flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        <PieChart options={pieChartOptions} series={pieChartData} />
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">Users</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
            {usersPercentage}%
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Corporate Users</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {corporateUsersPercentage}%
          </p>
        </div>
        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Customer Users</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {customerUsersPercentage}%
          </p>
        </div>
      </div>
    </Card>
  );
};

export default PieChartCard;
