import { useEffect, useState } from "react";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import { IoMdAnalytics, IoMdApps, IoMdBuild, IoMdHome } from "react-icons/io";
import { IoCar } from "react-icons/io5";
import { MdBarChart, MdCollections, MdCorporateFare, MdDashboard, MdUnarchive } from "react-icons/md";
import Widget from "components/widget/Widget";
import { getDashbaordData } from "components/service/dashboard.service";
import { FiUser, FiUserCheck, FiUserMinus, FiUserPlus, FiUsers } from "react-icons/fi";
import { FaHospitalUser } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { SiCountingworkspro } from "react-icons/si";
import PieChartCard from "./components/PieChartCard";
import DailyTraffic from "./components/DailyTraffic";
import PieChart from "components/charts/PieChart";
import Card from "components/card";
import LineChart from "components/charts/LineChart";
import BarChart from "components/charts/BarChart";


const Dashboard = () => {
  const [data, setData] = useState({ data: { userCount: 0, corporateUsersCount: 0, customerUsersCount: 0 } })

  // Assuming you have data available
  const { userCount, corporateUsersCount, customerUsersCount } = data.data;

  // Prepare the data for the pie chart
  const pieChartData = [userCount, corporateUsersCount, customerUsersCount];
  // // Prepare the options for the pie chart
  const pieChartOptions = {
    labels: ['Users', 'Corporate Users', 'Customer Users'],
  };

  const getAllDashbaordData = async () => {
    getDashbaordData().then((res) => {
      setData(res.data)
      console.log("Dashboard data is", res.data)
    }).catch((err) => {
      console.log("Error while fetching dashboard data", err)
    })
  }

  useEffect(() => {
    getAllDashbaordData()
  }, [])

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-5 2xl:grid-cols-3 3xl:grid-cols-10">
        {/* <Widget
          icon={<IoCar className="h-7 w-7" />}
          title={"Trucks"}
          subtitle={data.data && data.data.vehicleTruckCount ? data.data.vehicleTruckCount : 0}
        /> */}
        {/* <Widget
          icon={<MdCollections className="h-6 w-6" />}
          title={"Customer User"}
          subtitle={data.data && data.data.customerUsersCount ? data.data.customerUsersCount : 0}
        />
        <Widget
          icon={<FaHospitalUser className="h-7 w-7" />}
          title={"Corporate User"}
          subtitle={data.data && data.data.corporateUsersCount ? data.data.corporateUsersCount : 0}
        /> */}
        <Widget
          icon={<FiUsers className="h-6 w-6" />}
          title={"Users"}
          subtitle={data.data && data.data.userCount ? data.data.userCount + data.data.corporateUsersCount + data.data.customerUsersCount : 0}
        />
        <Widget
          icon={<MdCorporateFare className="h-7 w-7" />}
          title={"Corporate"}
          subtitle={data.data && data.data.corporateCount ? data.data.corporateCount : 0}
        />
        <Widget
          icon={<FiUserCheck className="h-6 w-6" />}
          title={"Trip"}
          subtitle={data.data && data.data.tripCount ? data.data.tripCount : 0}
        />
        {/* <Widget
          icon={<IoCar className="h-7 w-7" />}
          title={"Cars"}
          subtitle={data.data && data.data.vehicleCarCount ? data.data.vehicleCarCount : 0}
        /> */}
        <Widget
          icon={<HiUserGroup className="h-6 w-6" />}
          title={"Drivers"}
          subtitle={data.data && data.data.driverUsersCount ? data.data.driverUsersCount : 0}
        />
        <Widget
          icon={<SiCountingworkspro className="h-7 w-7" />}
          title={"Vehicle Count"}
          subtitle={data.data && data.data.vehicleCount ? data.data.vehicleCount : 0}
        />
        <Widget
          icon={<FiUser className="h-6 w-6" />}
          title={"Individual"}
          subtitle={data.data && data.data.individualUsersCount ? data.data.individualUsersCount : 0}
        />
      </div>

      {/* Users Pie Chart */}
      <div className="mt-5">
      <PieChartCard />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div>

      {/* Tables & Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2"> */}
      {/* Check Table */}
      {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

      {/* Traffic chart & Pie Chart */}

      {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div> */}

      {/* Complex Table , Task & Calendar */}

      {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

      {/* Task chart & Calendar */}

      {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
