import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import User from "views/admin/user/User";
import ResetPassword from "views/auth/ResetPassword";
import SignIn from "views/auth/SignIn";
import Corporate from "views/admin/corporate/Corporate";
import { AdminRoleContext } from "views/auth/AdminRoleContext";
import SearchResult from "components/search/SearchResult";


const App = () => {
  const [adminRole, setAdminRole] = React.useState(sessionStorage.getItem("adminRole"));
  return (
    <AdminRoleContext.Provider value={adminRole}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="user/*" element={<User />} />
        <Route path="login/" element={<SignIn />} />
        <Route path="change-password/" element={<ResetPassword />} />
        <Route path="corporate/" element={<Corporate />} />
        <Route path="/search-result" element={<SearchResult />} />
        <Route path="forbidden/" element={<h1>Forbidden</h1>} />
      </Routes>
    </AdminRoleContext.Provider>
  );
};

export default App;
