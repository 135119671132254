import { notification } from 'antd';
import { registerAdmin } from 'components/service/backoffice.service';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [emailAddress, setEmailAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [role, setRole] = useState("");
    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();
        registerAdmin({
            emailAddress, firstName, lastName, phoneNumber, role
        })
            .then((res) => {
                notification.success({
                    message: "Admin registered successfully",
                    description: "Admin has been registered successfully"
                });
                navigate('/admin/default')
            }).catch((err) => {
                notification.error({
                    message: "Failed to register admin",
                    description: "Failed to register admin"
                });
            })
    }

    return (
        <div className="mb-16 flex h-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center ">
            {/* Sign in section */}
            <form onSubmit={handleRegister} className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px] shadow-2xl" style={{ padding: '2rem' }}>
                <div className=" w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white text-center">
                        Sign Up
                    </h4>
                    <p className="mb-9 ml-1 text-base text-gray-600 text-center">
                        Register Admin User!
                    </p>
                    {/* Email */}
                    <label htmlFor="email" className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium">Email*</label>
                    <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        placeholder="mail@massitec.com"
                        id="email"
                        type="text"
                        onChange={(e) => setEmailAddress(e.target.value)}
                    />

                    {/* First Name */}
                    <label htmlFor="firstName" className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium">First Name*</label>
                    <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        placeholder="John"
                        id="firstName"
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                    />

                    {/* Last Name */}
                    <label htmlFor="lastName" className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium">Last Name*</label>
                    <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        placeholder="Doe"
                        id="lastName"
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    {/* Phone Number */}
                    <label htmlFor="phoneNumber" className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium">Phone Number*</label>
                    <input
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        placeholder="08012345678"
                        id="phoneNumber"
                        type="text"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {/* Role */}
                    <label htmlFor="role" className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium">Role*</label>
                    <select
                        id='role'
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option value="SUPER_ADMIN">SUPER_ADMIN</option>
                        <option value="ADMIN">ADMIN</option>
                    </select>
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        Sign Up
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Register