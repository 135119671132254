import axios from 'axios'

const instance = axios.create({
    baseURL:
        process.env.NODE_ENV === 'production'
            ? `${process.env.REACT_APP_BACKEND_URL}/api/v1`
            : 'https://maxon-backoffice-production.up.railway.app/api/v1'
});

instance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('maxon-token'); 
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default instance;
