import instance from './index';


export function userLogin({
    emailAddress = '',
    password = ''
} = {}) {
    if (!emailAddress || !password) {
        return Promise.reject('Email and password are required')
    }
    return instance.post('/auth/login', {
        emailAddress : emailAddress,
        password : password
    })
}

export function changeAdminPassword({
    oldPassword = '',
    newPassword = '',
    confirmPassword = ''
} = {}) {
    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
        return Promise.reject('All fields are required')
    }

    return instance.put('/change-password', {
        oldPassword : oldPassword,
        newPassword : newPassword,
        confirmPassword : confirmPassword
    })
}

export function registerAdmin({
    emailAddress = '',
    firstName = '',
    lastName = '',
    phoneNumber = '',
    role = '',
} = {}) {
    if (emailAddress === '' || firstName === '' || lastName === '' || phoneNumber === '' || role === '') {
        return Promise.reject('All fields are required')
    }
    return instance.post('/auth/admin22012/register', {
        emailAddress,
        firstName,
        lastName,
        phoneNumber,
        role
    })
}