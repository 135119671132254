import instance from "./index";

export function getAllVehicles() {
    return instance.get('/vehicle/all')
}

export function getVehicleById(id) {
    return instance.get(`/vehicle/${id}`)
}

export function disableVehicle(id,{
    disabled = false
}= {}) {
    return instance.put(`/vehicle/${id}/disable`,{
        disable :disabled
    })
}

export function searchVehicle({ 
    page = 0, size = 10, 
    id, 
    registrationNumber= '', 
    brand='', 
} = {}) {
    let params = {
        page: page,
        size: size,
        registrationNumber: registrationNumber,
        brand: brand,
    };
    if (id) {
        params.id = id;
    }
    return instance.get('/vehicle/search', { params: params });
}