import instance from "./index";

export function getAllUsers() {
    return instance.get('/users/all')
}

export function getUserById(id) {
    return instance.get(`/users/${id}`)
}

export function verifyUserIdentity(id, {
    EIdentificationType = '',
    idCardNumber = '',
    verified = false
} = {}) {
    return instance.put(`/users/${id}/identification/verify`, {
        identificationType: EIdentificationType,
        idCardNumber: idCardNumber,
        verified: verified
    })
}

export function disableUser(id,{
    disabled = false
}= {}) {
    return instance.put(`/users/${id}/disable`,{
        disable :disabled
    })
}

export function searchUser({ 
    page = 0, size = 10, 
    id, 
    firstName= '', 
    lastName='', 
    emailAddress='', 
    telephone='', 
    idCardNumber='' 
} = {}) {
    let params = {
        page: page,
        size: size,
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        telephone: telephone,
        idCardNumber: idCardNumber
    };
    if (id) {
        params.id = id;
    }
    return instance.get('/users/search', { params: params });
}