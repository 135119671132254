import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, notification } from "antd";
import { FiSearch } from 'react-icons/fi';
import { getAllVehicles } from 'components/service/vehicle.service';
import { disableVehicle } from 'components/service/vehicle.service';
import styled from 'styled-components';
import { searchVehicle } from 'components/service/vehicle.service';


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};

const HoverButton = styled(Button)`
    background-color: #008CBA;
    color: white;

    &:hover {
        background-color: #008CBA;
    }
`;

const Vehicle = () => {
    const [vehicles, setVehicles] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchField, setSearchField] = useState('');
    const [isViewPostModalVisible, setIsViewPostModalVisible] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [form] = Form.useForm();

    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedVehicle(post);
                        setIsViewPostModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "brand", title: "Brand", dataIndex: "brand" },
        { key: "registrationNumber", title: "Number Plate", dataIndex: "registrationNumber" },
        {
            key: "evehicleType",
            title: "Vehicle Type",
            dataIndex: "evehicleType",
            render: evehicleType => evehicleType,
            filters: [
                { text: 'Truck', value: 'TRUCK' },
                { text: 'Car', value: 'CAR' },
            ],
            onFilter: (value, record) => record.evehicleType.indexOf(value) === 0,
        },
        { key: "capacity", title: "Capacity", dataIndex: "capacity" },
        {
            key: 'evehicleStatus',
            title: 'Status',
            dataIndex: "evehicleStatus", render: evehicleStatus => evehicleStatus,
            filters: [
                { text: 'Active', value: 'ACTIVE' },
                { text: 'Inactive', value: 'INACTIVE' },
            ],
            onFilter: (value, record) => record.evehicleStatus.indexOf(value) === 0,
        },
        {
            key: 'disabled',
            title: 'Disabled',
            dataIndex: "disabled",
            render: (disabled) => disabled ? "Yes" : "No",
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ],
            onFilter: (value, record) => record.disabled === value,
        },
        {
            key: 'typeOfGoodsTransported',
            title: 'Type of Goods',
            dataIndex: 'typeOfGoodsTransported',
            render: typeOfGoodsTransported => typeOfGoodsTransported,
            filters: [
                { text: 'general_purpose', value: 'GENERAL_PURPOSE' },
                { text: 'construction', value: 'CONSTRUCTION' },
                { text: 'agriculture', value: 'AGRICULTURE' },
                { text: 'furniture', value: 'FURNITURE' },
                { text: 'electronic', value: 'ELECTRONIC' },
                { text: 'other', value: 'OTHER' },
            ],
            onFilter: (value, record) => record.typeOfGoodsTransported ? record.typeOfGoodsTransported.indexOf(value) === 0 : false,

        },
        {
            key: "action", title: "Action", align: "center",
            render: (vehicle) => [
                <Button
                    onClick={() => {
                        setSelectedVehicle(vehicle);
                        setIsViewPostModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                vehicle && vehicle.disabled ? (
                    <Button
                        style={{ backgroundColor: "#52c41a", color: "white" }}
                        onClick={() => {
                            confirmDisableOrEnableCorporate(vehicle, false);
                        }}
                    >
                        Enable
                    </Button>
                ) : (
                    <HoverButton
                        onClick={() => {
                            confirmDisableOrEnableCorporate(vehicle, true);
                        }}
                    >
                        Disable
                    </HoverButton>
                )
            ],
        },
    ]);

    const confirmDisableOrEnableCorporate = (user, shouldDisable) => {
        Modal.confirm({
            title: shouldDisable ? 'Confirm Disable' : 'Confirm Enable',
            content: `Are you sure you want to ${shouldDisable ? 'disable' : 'enable'} this vehicle?`,
            onOk() {
                handleDisableOrEnableCorporate(user, shouldDisable);
            },
        });
    };


    const loadVehicles = () => {
        getAllVehicles()
            .then((res) => {
                setVehicles(res.data);
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to get posts",
                });
            });
    };

    useEffect(() => {
        loadVehicles();
    }, []);

    const handleDisableOrEnableCorporate = (vehicle, shouldDisable) => {
        if (vehicle) {
            disableVehicle(vehicle.id, { disabled: shouldDisable })
                .then((response) => {
                    notification.success({
                        message: "User status updated successfully",
                    });
                    loadVehicles();
                }).catch((error) => {
                    notification.error({
                        message: "Failed to update User status",
                    });
                })
        } else {
            console.error('user is null');
        }
    }

    // const handleSearch = async (searchValue) => {
    //     if (searchValue.length > 0) {
    //         searchVehicle({ registrationNumber: searchValue})
    //             .then((response) => {
    //                 setSearch(response.data);
    //             }).catch((error) => {
    //                 console.log("Error: ", error)
    //             })
    //     }else {
    //         notification.error({
    //             message: "No record found for the search value",
    //         });
    //         setSearch([]);
    //     }
    // };

    const handleSearch = async (searchValue) => {
        if (searchValue.length > 0) {
            let searchParams = {};
            if (searchField === 'registrationNumber') {
                searchParams.registrationNumber = searchValue;
            } else if (searchField === 'brand') {
                searchParams.brand = searchValue;
            }
            searchVehicle(searchParams)
                .then((response) => {
                    setSearch(response.data);
                }).catch((error) => {
                    console.log("Error: ", error)
                })
        } else {
            notification.error({
                message: "No record found for the search value",
            });
            setSearch([]);
        }
    };

    return (
        <div style={{ marginTop: '5rem' }}>
            {/* modal view  */}
            <Modal
                open={isViewPostModalVisible}
                onCancel={() => {
                    setIsViewPostModalVisible(false);
                }}
                footer={[
                    <Button
                        onClick={() => {
                            setIsViewPostModalVisible(false);
                        }}
                    >
                        Cancel
                    </Button>,
                ]}
            >
                <Form
                    {...layout}
                    name="nest-messages"
                    form={form}
                    style={{ paddingTop: "35px" }}
                >
                    <Form.Item name={"brand"} label="Brand">
                        <p style={{ margin: 0 }}>{selectedVehicle?.brand}</p>
                    </Form.Item>

                    <Form.Item name={"registrationNumber"} label="Number Plate">
                        <p style={{ margin: 0 }}>{selectedVehicle?.registrationNumber}</p>
                    </Form.Item>

                    <Form.Item name={"capacity"} label="Capacity">
                        <p style={{ margin: 0 }}>{selectedVehicle?.capacity}</p>
                    </Form.Item>
                    <Form.Item name={"color"} label="Color">
                        <p style={{ margin: 0 }}>{selectedVehicle?.color}</p>
                    </Form.Item>
                    <Form.Item name={"evehicleType"} label="Vehicle Type">
                        <p style={{ margin: 0 }}>{selectedVehicle?.evehicleType}</p>
                    </Form.Item>
                    <Form.Item name={"evehicleStatus"} label="Status">
                        <p style={{ margin: 0 }}>{selectedVehicle?.evehicleStatus}</p>
                    </Form.Item>
                    <Form.Item name={"typeOfGoodsTransported"} label="Type of Goods">
                        <p style={{ margin: 0 }}>{selectedVehicle?.typeOfGoodsTransported}</p>
                    </Form.Item>
                    {selectedVehicle?.driverUser && (
                        <Form.Item name={"driverUser"} label="Driver">
                            <p style={{ margin: 0 }}>{selectedVehicle.driverUser.firstName + " " + selectedVehicle.driverUser.lastName}</p>
                        </Form.Item>
                    )}
                </Form>
            </Modal>

            {/* main content */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1.5rem' }}>
                <div className="relative mt-[3px] flex h-[45px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:flex-grow-0 md:gap-1 xl:w-[250px] xl:gap-2">
                    <div className="flex h-full items-center rounded-full bg-white  text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                        <p className="pl-3 pr-2 text-xl">
                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                        </p>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="block h-full w-full rounded-full bg-white  text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch(e.target.value);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* <Table columns={tableCols} dataSource={vehicles} /> */}
            <Table columns={tableCols} dataSource={search.length > 0 ? search : vehicles} />
        </div>
    )

}

export default Vehicle