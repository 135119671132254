import React from "react";
import MainDashboard from "views/admin/default";
import {
  MdHome,
  MdPerson,
  MdCarRental,
  MdCorporateFare,
  MdSearch,
} from "react-icons/md";
import User from "views/admin/user/User";
import Vehicle from "views/admin/vehicle/Vehicle";
import Corporate from "views/admin/corporate/Corporate";
import Register from "views/auth/Register";
import { withAdminProtection } from "views/auth/Auth";
import SearchResult from "components/search/SearchResult";


const ProtectedRegister = withAdminProtection(Register);
const adminRole = sessionStorage.getItem('adminRole');
const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <MdPerson className="h-6 w-6" />,
    component: <User />,
  },
  {
    name: "Vehicle",
    layout: "/admin",
    path: "vehicle",
    icon: <MdCarRental className="h-6 w-6" />,
    component: <Vehicle />,
  },
  {
    name: "Corporate",
    layout: "/admin",
    path: "corporate",
    icon: <MdCorporateFare className="h-6 w-6" />,
    component: <Corporate />,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <UserProfile />,
  // },
  //  
  ...(adminRole === 'SUPER_ADMIN' ? [{
    name: "Register Admin",
    layout: "/admin",
    path: "register",
    icon: <MdPerson className="h-6 w-6" />,
    component: <ProtectedRegister adminRole={adminRole} />,
  }] : []),
  {
    
    name: "User Search",
    layout: "/admin",
    path: "search-result",
    // icon: <MdSearch className="h-6 w-6" />,
    component: <SearchResult />,
  },
];
export default routes;
