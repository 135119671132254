import { Button, Form, Modal, Table, notification } from 'antd';
import { disableUser } from 'components/service/user.service';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};

const HoverButton = styled(Button)`
    background-color: #008CBA;
    color: white;

    &:hover {
        background-color: #008CBA;
    }
`;

const SearchResult = () => {
    const location = useLocation();
    const search = location.state?.search;
    const [searchResult, setSearchResult] = React.useState([]);
    const [form] = Form.useForm()
    const [isUpdateUserModalVisible, setIsUpdateUserModalVisible] = useState(false);
    const [isViewUserModalVisible, setIsViewUserModalVisible] = useState(false);
    const [isDisableUserModalVisible, setIsDisableUserModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedUser(post);
                        setIsViewUserModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "name", title: "Name", render: (text, record) => `${record.firstName} ${record.lastName}`, },
        { key: "emailAddress", title: "Email", dataIndex: "emailAddress" },
        { key: "telephone", title: "Telephone", dataIndex: "telephone" },
        {
            key: "euserType",
            title: "User Type",
            dataIndex: "euserType",
            filters: [
                { text: 'Driver', value: 'DRIVER' },
                { text: 'Corporate', value: 'CORPORATE' },
            ],
            onFilter: (value, record) => record.euserType.indexOf(value) === 0,
        },
        {
            key: "disabled",
            title: "Disabled",
            dataIndex: "disabled",
            render: (disabled) => disabled ? "Yes" : "No"
        },
        {
            key: "role",
            title: "Role",
            dataIndex: "role", render: roles => roles[0].name,
            filters: [
                { text: 'Admin', value: 'ADMIN' },
                { text: 'User', value: 'USER' },
            ],
            onFilter: (value, record) => record.role[0].name.indexOf(value) === 0,
        },
        {
            key: "action", title: "Action", align: "center",
            render: (user) => [
                <Button
                    onClick={() => editPost(user)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Verify
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedUser(user);
                        setIsViewUserModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                user && user.disabled ? (
                    <Button
                        style={{ backgroundColor: "#52c41a", color: "white" }}
                        onClick={() => {
                            confirmDisableOrEnableUser(user, false);
                        }}
                    >
                        Enable
                    </Button>
                ) : (
                    <HoverButton
                        onClick={() => {
                            confirmDisableOrEnableUser(user, true);
                        }}
                    >
                        Disable
                    </HoverButton>
                )
            ],
        },
    ]);

    const editPost = (user) => {
        setSelectedUser(user);
        form?.setFieldsValue({
            firstName: user.firstName,
            lastName: user.lastName,
            emailAddress: user.emailAddress,
            identificationType: user.identificationType,
            idCardNumber: user.idCardNumber,
            telephone: user.telephone,
            disabled: user.disabled,
            verified: user.verified,
            idCardNumberVerified: user.idCardNumberVerified,
            euserType: user.euserType,
            phoneNumberVerified: user.phoneNumberVerified,
        });
        setIsUpdateUserModalVisible(true);
    };

    const confirmDisableOrEnableUser = (user, shouldDisable) => {
        Modal.confirm({
            title: shouldDisable ? 'Confirm Disable' : 'Confirm Enable',
            content: `Are you sure you want to ${shouldDisable ? 'disable' : 'enable'} this user?`,
            onOk() {
                handleDisableOrEnableUser(user, shouldDisable);
            },
        });
    };

    const handleDisableOrEnableUser = (user, shouldDisable) => {
        if (user) {
            disableUser(user.id, { disabled: shouldDisable })
                .then((response) => {
                    notification.success({
                        message: "User status updated successfully",
                    });
                    setIsDisableUserModalVisible(false);
                    // getUsers();
                }).catch((error) => {
                    notification.error({
                        message: "Failed to update User status",
                    });
                })
        } else {
            console.error('user is null');
        }
    }

    useEffect(() => {
        if(search) {
            setSearchResult(search);
        }
    }, [search])

    return (
        <div className='mt-20'>
            <Table columns={tableCols} dataSource={searchResult} />
        </div>
    )
}

export default SearchResult